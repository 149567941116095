<template>
    <div v-if="currentToolSelected && currentToolSelected !== 'search-engine-tool' && currentToolSelected !== 'other-tool'" id="search-queries" class="search-queries relative h-full  flex" @scroll="alignQueryLoader">
        <div class="bg-white min-h-48 h-full self-start shadow-md border border-solid border-gray-200 sm:rounded-md relative overflow-hidden flex flex-col flex-grow" :class="{'cursor-not-allowed opacity-75 pointer-events-none': disabled}">
            <div class="search-queries-header flex px-2 py-2 justify-between bg-brand-area-color items-center sticky top-0">
                <div class="flex flex-col">
                    <div class="font-semibold text-gray-700 mt-3">Search Queries</div>
                    <!-- <div class="text-brand text-sm cursor-pointer hover:text-brand-hover flex items-center mt-2" @click="selectAllQueries(allQuerySelected, true)" v-if="filteredQueries.length > 0 && filterShow && $route.name !== 'monitoring'">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span class="ml-1"> {{ allQuerySelected ? "Select" : "Deselect" }} All Queries </span>
                    </div> -->
                </div>
                <div class="flex items-center mt-3">
                    <svg v-if="filterShow && $route.name !== 'monitoring'" @click="onFilterClick" v-tippy content="Filters" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer outline-none mr-4" name="ungroupedComponent" width="24" height="16.799" viewBox="0 0 24 16.799">
                        <path id="filters-solid" d="M22.488,32a1.5,1.5,0,0,1,1.1,2.515l-5.6,6.091V46.4a1.2,1.2,0,0,1-1.92.96l-2.4-1.8a1.192,1.192,0,0,1-.48-.96V40.606l-5.6-6.091a1.434,1.434,0,0,1-.27-1.618A1.483,1.483,0,0,1,8.655,32Zm-21,2.4H6.142a2.711,2.711,0,0,0,.559.926l5.287,5.715v.66l-1.2,1.3V47.6a1.2,1.2,0,0,1-1.92.96l-2.4-1.8a1.192,1.192,0,0,1-.48-.96V43.006l-5.6-6.09a1.5,1.5,0,0,1,1.1-2.516Z" transform="translate(0.012 -32)" fill="#0d69d5" />
                    </svg>
                    <!-- <div class="bg-red-500 w-4 h-4 -ml-4 -mt-4 text-xs font-bold text-center text-white rounded-full p-0.5 mx-auto shadow" v-if="filterShow && toolFiltersCount">
                        !
                    </div> -->
                    <!-- <font-awesome-icon icon="check-circle" class="h-6 w-6 -ml-4 -mt-4 text-xl text-blue-600 font-bold text-center text-white rounded-full p-0.5 mx-auto shadow"> </font-awesome-icon> -->
                    <div class="px-1.5 py-0.5 -ml-6 -mt-6 rounded-full flex text-center items-center bg-red-500 text-sm cursor-pointer" v-if="filterShow && $route.name !== 'monitoring'"  title="Filters Applied">
                        <div class="mx-auto text-xs text-white">{{ toolFiltersCount }}</div>
                    </div>
                    <font-awesome-icon icon="sync" class="cursor-pointer hover:text-blue-400 hover:animate-spin outline-none" :class="{'animate-spin': queriesLoading}" v-tippy content="Refresh Search Queries" @click="handleRefreshQueries"> </font-awesome-icon>
                </div>
            </div>
            <div class="flex px-2 bg-brand-area-color items-center text-sm sticky top-0" v-if="filteredQueries.length > 0 && filterShow && $route.name !== 'monitoring'">
                <!-- <span class="text-brand text-sm cursor-pointer hover:text-brand-hover flex flex-col ml-2 items-center" @click="selectAllQueries(allQuerySelected, true)" v-if="filteredQueries.length > 0 && filterShow && $route.name !== 'monitoring'">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                </span> -->
                <input type="checkbox" class="form-checkbox mr-2 h-4 w-4 text-brand cursor-pointer" @click="selectAllQueries(allQuerySelected, true)" v-if="filteredQueries.length > 0 && filterShow && $route.name !== 'monitoring'" :checked="!allQuerySelected" />
                <!-- <span class="ml-1"> {{ allQuerySelected ? "Select" : "Deselect" }} All Queries </span> -->
                <!-- <span class="mb-2 flex items-center justify-end border-0 border-solid border-gray-200 bg-gray-50" v-if="filteredQueries.length > 0 && filterShow && $route.name !== 'monitoring'"> -->
                <span> {{ allQuerySelected ? "Select" : "Deselect" }} All</span>
                <!-- <button @click="onRunQueries()" :disabled="isEnabledApplyButton" :class="isEnabledApplyButton ? 'bg-gray-500 cursor-not-allowed' : 'bg-brand cursor-pointer hover:bg-brand-hover'" class="border-none rounded text-xs p-1 mb-2 flex items-center ml-auto text-white">
                    View Results
                </button> -->
            </div>
            <div v-if="filteredQueries.length > 0" class="border-0 border-solid border-gray-200 border-t border-b max-h-full scroll-bar overflow-y-auto h-full flex-grow">
                <div
                    class="relative flex items-center justify-between px-2 py-3 border-0 border-b border-solid border-gray-200 hover:bg-blue-50"
                    v-for="query in filteredQueries"
                    :key="query._id"
                    @click="handleClick(query)"
                    :class="{'bg-blue-100': selectedRow === query._id || (query.run_query === true && query.selected === true), 'line-through': query.monitoring_status === 'disabled' && $route.name === 'monitoring', 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled}"
                >
                    <!-- Removed query keys name which are page_size and start -->

                    <div class="flex items-center align-middle" :style="getQueryStyle(query)">
                        <div class="flex items-center">
                            <input v-if="filterShow && $route.name !== 'monitoring'" type="checkbox" class="form-checkbox h-4 w-4 text-brand mr-2 cursor-pointer" @change="handleChange($event, query._id)" @click.stop :checked="query.run_query" />
                            <div v-if="monitoring">
                                <neo-toggle-switch :status="query.is_monitoring" :disabled="disabled" @click="handleMonitoringStatus(query)" :key="query._id" class="mx-auto p-1 mr-2" />
                                <span class="text-xs text-gray-500">Monitor</span>
                            </div>
                        </div>
                        <span class="truncate w-full ml-2" :title="viewQuery(query)">
                            <!-- <span class=" truncate w-full ml-2" :title="viewQuery(query)"> -->
                            {{ viewQuery(query) }}
                            <!-- tools to have saved count  -->
                            <div v-if="showResultsCount" class="flex pt-2 justify-between items-center w-full">
                                <div class="flex gap-3">
                                    <!-- <span v-tippy content="Saved Results" class="flex text-gray-400 items-center text-xs gap-1">
                                        <font-awesome-icon icon="save" class="text-xs text-blue-500" style="font-size: 10px"> </font-awesome-icon>
                                        {{ query.saved_results }}
                                    </span> -->
                                </div>
                                <div class="text-xs ml-auto text-gray-400 font-semibold">Total: {{ query.total_results }}</div>
                            </div>
                        </span>
                        <div class="ml-auto mb-2" v-if="currentToolSelected == 'news-tool'">
                            <span class="relative inline-block">
                                <!-- <img class="cursor-pointer" :src="require('@/assets/icons/search-engines/analyser.svg')" /> -->
                            </span>
                        </div>
                    </div>

                    <!-- {{query.status}} -->
                    <div class="flex">
                        <div class="px-2 py-0.5 rounded-full bg-brand flex text-center items-center" v-if="query.events && ($route.name === 'monitoring' || $route.name === 'monitoring-ro')"  title="Monitoring Events">
                            <div class="font-semibold text-xs text-white mx-auto">{{ query.events }}</div>
                        </div>

                        <span v-if="$route.name !== 'monitoring' && $route.name !== 'monitoring-ro' && (query.status === 'PENDING' || query.status === 'RUNNING' || (query.status === 'DONE' && !query.visited))" class="flex mr-1">
                            <span class="rounded-full flex h-3 w-3 bg-blue-500" v-if="query.status === 'PENDING'"></span>
                            <svg v-else-if="query.status == 'DONE' && !query.visited" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                            <span class="flex max-h-4 w-3" v-else-if="query.status === 'RUNNING'">
                                <neo-loader :loading="true"></neo-loader>
                            </span>
                            <span v-else></span>
                        </span>

                        <div class="flex -right-2 items-center monitoring-settings-panel pr-1" v-if="$route.name === 'monitoring'" @click="handleSettings($event)">
                            <span :name="query._id" @click="treeData = query">
                                <font-awesome-icon icon="ellipsis-v" class="p-2 h-4 box-size-initial text-gray-400 focus:outline-none" v-if="!query.frequency" v-tippy :content="`Monitoring Settings`"> </font-awesome-icon>
                                <!-- <span class="text-green-600" v-else v-tippy :content="`Frequency`"> {{ getFrequency(query.frequency) }} </span> -->
                                <!-- <div class="px-2 h-5 bg-brand rounded-full flex text-center items-center">
                                <div class="text-xs font-semibold text-white mx-auto" style="line-height: initial">{{ query.frequency }}</div>
                            </div> -->

                                <a class="p-2 text-xs font-semibold text-brand mx-auto no-underline hover:underline" v-else v-tippy :content="`Frequency : ${getFrequency(query.frequency)}`"> {{ query.frequency }} d </a>
                            </span>
                            <tippy :onHidden="hideOptionsBgLayer" :onShow="showOptionsBgLayer" :to="query._id" arrow arrowType="round" interactive theme="light monitoring" trigger="click" maxWidth="500" size="large">
                                <options-widget
                                    :ref="`options-widget-${query._id}`"
                                    :monitoring_data="{
                                        monitoring_status: query.monitoring_status === 'enabled',
                                        frequency: query.frequency,
                                    }"
                                    source="monitoring-query"
                                    :tool="currentToolSelected"
                                    @updateFrequency="updateFrequency(query, true)"
                                    @updateMonitoring="updateMonitoring"
                                >
                                </options-widget>
                            </tippy>
                            <div ref="optionsbgLayer" id="optionsbgLayer" @click="isOpen = false"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-base text-gray-500 px-2 h-full flex-grow" v-else>
                No Query Found!
            </div>
            <span id="queries-loader" class="absolute bg-blue-100 px-4 py-2 max-h-20 rounded-lg text-center top-1/2 w-full" v-if="queriesLoading || disabled">
                <div class="spinner-4" style="margin-left: calc(50% - 15px)"></div>
                <span class="ml-2">Please wait... Fetching Data.</span>
            </span>
            <!-- <div class="sticky bottom-0 flex items-center justify-end p-4 border-t border-0 border-solid border-gray-200 bg-gray-50" v-if="filteredQueries.length > 0 && filterShow && $route.name !== 'monitoring'"> -->
            <!-- <button @click="selectAllQueries(false, false)" class="mr-2 border-none py-2 px-4 bg-gray-200 cursor-pointer rounded text-sm h-full flex items-center text-brand hover:bg-brand hover:text-white">
                    Reset
                </button> -->
            <!-- <button @click="onRunQueries()" :disabled="isEnabledApplyButton" :class="isEnabledApplyButton ? 'bg-gray-500 cursor-not-allowed' : 'bg-brand cursor-pointer hover:bg-brand-hover'" class="border-none rounded text-sm h-full py-2 px-4 flex items-center text-white"> -->
            <!-- <font-awesome-icon icon="filter" class="w-4 mr-1" /> -->
            <!-- Run Queries
                </button>
            </div> -->
        </div>
    </div>
</template>

<script>
import NeoLoader from "@/components/loader";
import NeoToggleSwitch from "@/components/toggle-switch";
import optionsWidget from "@/components/options-widget";
import {TippyComponent} from "vue-tippy";
import {mapMutations, mapActions, mapGetters} from "vuex";
import {capitalize, cloneDeep} from "lodash";

export default {
    name: "SearchQueries",
    data() {
        return {
            test: [],
            mouseOverMonitoring: false,
            componentKey: 0,
            queriesLoading: false,
            treeData: null,
            filterShowTool: ["adverse-media-tool", "offshoreleaks-tool", "news-tool"],
            selectedQueries: [],
        };
    },
    props: {
        currentToolSelected: String,
        queries: Array,
        input_value_list: Array,
        monitoring: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        toolMonitoring: {
            type: Boolean,
            default: false,
        },
        toolFiltersCount: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapGetters([ "searchedQueries", "getNeoCaseId", "getAnalyzerOptions", "getPermissions", "getAutoAnalyze", "getSelectedToolQuery"]),
        showResultsCount() {
            return ["adverse-media-tool", "news-tool", "company-name", "offshoreleaks-tool", "lexis-bridger-tool"].includes(this.currentToolSelected);
        },
        filteredQueries() {
            let filtered = [...this.queries];
            const values = this.input_value_list?.map((n) => n.value);
            if (values?.length > 0) {
                filtered = filtered.filter((query) => {
                    if (this.currentToolSelected === "person-identifier-tool" && (Array.isArray(query?.query?.name) || Array.isArray(query?.query?.email) || Array.isArray(query?.query?.phone) || Array.isArray(query?.query?.locations) || Array.isArray(query?.query?.social_details) || Array.isArray(query?.query?.jobs) || Array.isArray(query?.query?.educations))) {
                        return this.viewQuery(query)
                            .split(",")
                            .some((n1) => values.includes(n1?.trim()));
                    } else return Object.values(query.query).find((n1) => values.includes(n1?.trim()));
                });
            }
            // if ((this.$route.name === "monitoring" || this.$route.name === "monitoring-ro") && !this.toolMonitoring) {
            if (this.$route.name === "monitoring" || this.$route.name === "monitoring-ro") {
                filtered = filtered.filter((query) => query.is_monitoring);
                return filtered
                    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    ?.sort((a) => {
                        if (a.monitoring_status === "enabled") return -1;
                        else return 1;
                    });
            }
            return filtered?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        },
        selectedRow() {
            return this.getSelectedToolQuery?.query_id ?? this.$route?.query?.query_id;
        },
        tabName() {
            return this.$route.name;
        },
        filterShow() {
            return this.filterShowTool.includes(this.currentToolSelected);
        },
        allResultTools() {
            return ["adverse-media-tool", "news-tool", "offshoreleaks-tool"].includes(this.currentToolSelected);
        },
        allQuerySelected() {
            return !this.filteredQueries.every((query) => query.run_query);
        },
        isEnabledApplyButton() {
            return !this.filteredQueries.some((query) => query.run_query);
        },
       
    },
    components: {
        NeoLoader,
        NeoToggleSwitch,
        "options-widget": optionsWidget,
        tippy: TippyComponent,
    },
    mounted() {
        this.alignQueryLoader();
    },
    methods: {
        ...mapMutations(["SET_SELECTED_TOOL_QUERY", "SET_SEARCHED_QUERIES"]),
        ...mapActions(["getToolsStatus"]),
        alignQueryLoader() {
            let totalHeight = document.getElementById("search-queries")?.clientHeight;
            let position = document.getElementById("search-queries")?.scrollTop;
            let component = document.getElementById("queries-loader");
            if (component) component.style.top = position ? `calc(${totalHeight / 2}px - 10px + ${position}px)` : `calc(${totalHeight / 2}px - 10px)`;
        },
        handleClick(query, runQuery) {
            if (query) {
                let selectedQuery = {query_id: query._id, query_string: this.viewQuery(query), search_string: this.viewQuery(query), query};
                this.SET_SELECTED_TOOL_QUERY(selectedQuery);
                if (!runQuery) this.$emit("searchQueryClicked", query);
            } else {
                this.SET_SELECTED_TOOL_QUERY(null);
            }
        },
        viewQuery({query}) {
            if (this.currentToolSelected === "adverse-media-tool") {
                return `${query?.name} ( ${query.sources?.filter((el) => el !== "offshoreleaks")?.join(", ")} )`;
            }else if (this.currentToolSelected === "lexis-bridger-tool") {
                if (query.entity_type === "Individual") return `${query?.person_entity?.full_name} (${query?.entity_type})`;
                else return `${query?.company_entity?.name} (${query?.entity_type})`;
            } 
            else if (this.currentToolSelected === "offshoreleaks-tool") {
                return `${query?.name} ( ${query.sources?.join(", ")} )`;
            } else if (this.currentToolSelected === "news-tool") {
                let plain_query = query.query?.split(" near")[0];
                return `${plain_query} ( ${query.sources?.join(", ")} )`;
            } else if (this.currentToolSelected === "company-name") {
                return query.sources?.length ? `${query.name ?? query.officer ?? query.address} (${query?.sources?.map((src) => capitalize(src))?.join(", ")})` : `${query.name ?? query.officer ?? query.address}`;
            } else if (this.currentToolSelected === "court-records-tool") {
                let searchQueryString = "";
                // according to old tools search judy
                if (query?.search_query) {
                    return `${query?.search_query}`;
                    // according to new tool search judy or uni
                } else if (query?.case_search_list.length) {
                    const dropDownItems = ["condition_operator", "party_types", "partyTypes", "proximity", "representation", "scope", "search_type"];
                    query?.case_search_list.forEach((row) => {
                        for (const key in row) {
                            if (!dropDownItems.includes(key)) {
                                searchQueryString = searchQueryString + row[key] + (row[key].toString().split(" ").length > 1 ? "" : " ");
                                searchQueryString = searchQueryString + ", ";
                            }
                        }
                    });
                    searchQueryString = searchQueryString
                        .replaceAll(" OR ", ", ")
                        .replaceAll(" AND ", ", ")
                        .replaceAll(" NOT ", ", ")
                        .replace(/,\s*$/, "");
                    let names = [];
                    if (query?.sources) {
                        names = query?.sources.map((src) => {
                            let name = src.toLowerCase();
                            return name[0].toUpperCase() + name.slice(1);
                        });
                    }
                    return `${searchQueryString} ( ${names.join(", ") || " "} )`;
                } else {
                    return searchQueryString;
                }
            } else if (this.currentToolSelected === "gdc-tool") {
                let searchQuery = [];
                if (query.identity.first_name || query.identity.middle_name || query.identity.last_name) {
                    searchQuery.push(`${query.identity.first_name + " " + query.identity.middle_name + " " + query.identity.last_name}`);
                }
                if (query.identity.dob) {
                    searchQuery.push(`${query.identity.dob}`);
                }
                if (query.identity?.nationalid?.length > 0) {
                    let ids = [];
                    query.identity.nationalid.forEach((el) => {
                        if (el.id) ids.push(el.id);
                    });
                    if (ids.length > 0) {
                        let nationalids = ids.join(",");
                        searchQuery.push(nationalids);
                    }
                }
                if (query.identity?.organization_name) {
                    searchQuery.push(`${query.identity.organization_name}`);
                }
                if (query.identity.business && query.identity.business.length > 0) {
                    let businessids = [];
                    query.identity.business.forEach((el) => {
                        if (el.id) businessids.push(el.id);
                    });
                    if (businessids.length > 0) {
                        let business_ids = businessids.join(",");
                        searchQuery.push(business_ids);
                    }
                }
                if (query.address.addressLine1 != "" || query.address.houseNumber != "" || query.address.countryCode != "") {
                    searchQuery.push(`${query.address.houseNumber + " " + query.address.addressLine1 + " " + query.address.countryCode}`);
                }
                if (query.address?.district && query.address?.district != "") {
                    searchQuery.push(`${query.address.district}`);
                }
                if (query.address.locality != "" || query.address.postalCode != "" || query.address.province != "") {
                    searchQuery.push(`${query.address.locality + " " + query.address.province + " " + query.address.postalCode}`);
                }
                if (query.email.full_email_address != "") {
                    searchQuery.push(`${query?.email.full_email_address}`);
                }
                if (query.phone.phone_number != "") {
                    searchQuery.push(`${query.phone.phone_number}`);
                }
                return searchQuery.join();
            } else if (this.currentToolSelected == "person-identifier-tool") {
                if (Array.isArray(query?.name) || Array.isArray(query?.email) || Array.isArray(query?.phone) || Array.isArray(query?.locations) || Array.isArray(query?.social_details) || Array.isArray(query?.jobs) || Array.isArray(query?.educations)) {
                    let queryView = [];
                    query?.name?.forEach((res) => {
                        let name = "";
                        if (res && (res.first_name || res.middle_name || res.last_name)) {
                            name = ((res.first_name || "") + " " + (res.middle_name || "") + " " + (res.last_name || "")).trim();
                        }
                        if (name) queryView.push(name);
                    });
                    query?.email?.forEach((res) => {
                        if (res && res.email) {
                            queryView.push(res.email);
                        }
                    });
                    query?.phone?.forEach((res) => {
                        if (res && res.phone) {
                            queryView.push(res.phone);
                        }
                    });
                    query?.location?.forEach((res) => {
                        if (res && (res.country || res.state || res.city)) {
                            queryView.push(res.country + " " + res.state + " " + res.city);
                        }
                    });
                    query?.social_details?.forEach((res) => {
                        if (res && res.social_profile) {
                            queryView.push(res.social_profile);
                        }
                        if (res && res.username) {
                            queryView.push(res.username);
                        }
                    });
                    query?.jobs?.forEach((res) => {
                        if (res && res.organization) {
                            queryView.push(res.organization);
                        }
                    });
                    query?.educations?.forEach((res) => {
                        if (res && res.school) {
                            queryView.push(res.school);
                        }
                    });
                    return queryView.join(", ");
                } else {
                    return Object.entries(query)
                        .map((n) => (n[0] != "page_size" && n[0] != "discover_email" && n[0] != "top_match" && n[0] != "start" ? n[1] : ""))
                        .filter((n) => n)
                        .join(", ");
                }
                
            } else if (this.currentToolSelected === "whatsapp-tool") {
                if (query.country_code) {
                    return "+" + query?.country_code + query?.number;
                } else {
                    return query?.number;
                }
            } else if (this.currentToolSelected === "linkedin-tool") {
                query = {...query, email_discover: query["discover_email"]};
                if (query.discovery) {
                    query.discovery = "discovery";
                }
            } else if (this.currentToolSelected === "facebook-tool") {
                if (query.country_code) {
                    return query.country_with_code.split("(")[1].split(")")[0] + query?.number;
                }
            }
            let name = Object.keys(query).filter((n) => n == "name");
            if (name) {
                query = {name: query["name"], ...query};
            }
            console.log("123123")
            console.log(Object.entries(query)
                .map((n) => (n[0] != "page_size" && n[0] != "discover_email" && n[0] != "top_match" && n[0] != "start" ? n[1] : ""))
                .filter((n) => n)
                .join(", "))
            return Object.entries(query)
                .map((n) => (n[0] != "page_size" && n[0] != "discover_email" && n[0] != "top_match" && n[0] != "start" ? n[1] : ""))
                .filter((n) => n)
                .join(", ");
        },
        handleMonitoringStatus(query) {
            if (!this.disabled) {
                query.is_monitoring = !query.is_monitoring;
                this.$forceUpdate();
                this.$emit("changeMonitoringStatus", query);
            }
        },
        handleSettings(event) {
            event.stopPropagation();
        },

        getFrequency(frequency) {
            let days = parseInt(frequency, 10) || 0;
            return `${days} days`;
        },

        showOptionsBgLayer() {
            this.isOpen = true;
            const optionsbgLayer = document.getElementById("optionsbgLayer").cloneNode();
            const bgCopy = document.body.appendChild(optionsbgLayer);
            bgCopy.classList = "bg-layer fixed inset-0 bg-black opacity-30 z-50";
            bgCopy.id = "options-bg-copy";
            bgCopy.style.zIndex = " 590";
            this.$refs[`options-widget-${this.treeData._id}`][0]?.initiateOptionsWidget();
        },
        hideOptionsBgLayer() {
            this.isOpen = false;
            const bgCopy = document.getElementById("options-bg-copy");
            bgCopy.remove();
            this.$refs[`options-widget-${this.treeData._id}`][0]?.resetOptionsWidget();
            return true;
        },

        updateFrequency(query, isQuery) {
            this.$emit("updateFrequency", {
                query: query,
                isQuery: isQuery,
                maskedQuery: this.viewQuery(query),
            });
        },

        updateMonitoring(data) {
            this.$emit("updateMonitoring", data);
        },

        async handleRefreshQueries() {
            this.queriesLoading = true;
            await this.getToolsStatus();
            this.queriesLoading = false;
            this.$emit("refreshQueries");
        },

        getQueryStyle(query) {
            if (this.$route.name === "monitoring" || this.$route.name === "monitoring-ro") {
                if (query.events) return "width: calc(100% - 95px)";
                else return "width: calc(100% - 55px)";
            } else {
                if (query.status === "PENDING" || query.status === "RUNNING" || (query.status === "DONE" && !query.visited)) return "width: calc(100% - 24px)";
                else return "width: calc(100% - 4px)";
            }
        },
        onFilterClick() {
            this.$modal.show("tools-filter", {currentToolSelected: this.currentToolSelected});
        },
        selectAllQueries(run_query) {
            const allQueries = cloneDeep(this.searchedQueries).filter((query) => query.tool != this.currentToolSelected);
            let queries = cloneDeep(this.filteredQueries);
            queries = queries.map((query) => ({...query, run_query}));
            this.SET_SEARCHED_QUERIES([...allQueries, ...queries]);
        },
        handleChange({target}, query_id) {
            const queryIndex = cloneDeep(this.searchedQueries).findIndex((query) => query._id == query_id);
            if (queryIndex !== -1) {
                let allQueries = cloneDeep(this.searchedQueries);
                allQueries[queryIndex]["run_query"] = target.checked;
                this.SET_SEARCHED_QUERIES(allQueries);
            }
        },
        onRunQueries() {
            const queries = this.filteredQueries.filter((query) => query.run_query).map((query) => query._id);
            this.selectedQueries = queries;
            this.$emit("onRunQueries", {
                case_id: this.getNeoCaseId,
                tool: this.currentToolSelected,
                queries,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.search-queries {
    .search-queries-header {
        z-index: 1 !important;
    }
    // width: 18%;
    // flex: 0 0 16%;
    height: calc(100% - 132px);
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}
::v-deep {
    .loader {
        width: 16px !important;
        height: 16px !important;
    }

    .toggle-switch {
        @apply h-3;
        @apply w-7;
    }

    .toggle-view {
        @apply h-2;
        @apply w-2;
        @apply ml-1;
    }
}

.box-size-initial {
    box-sizing: initial !important;
}

.spinner-4 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 1px;
    margin-right: 1rem;
    background: conic-gradient(#0000 10%, #0d69d5) content-box;
    -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg), radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 calc(100% - 8px));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: s4 1s infinite steps(10);
}
@keyframes s4 {
    to {
        transform: rotate(1turn);
    }
}
</style>
